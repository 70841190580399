import { FormatedImage } from '@pretto/website/src/components/FormatedImage/FormatedImage'
import { BlocAuthorStartProps } from '@pretto/website/src/templates/blocs/rows/heroPicture/components/BlocAuthorStart/BlocAuthorStart'

import * as S from './HeroPicture.styles'

export type HeroPictureProps = {
  authorProps: Omit<BlocAuthorStartProps, 'title'>
  category?: string
  image: string
  isColored?: boolean
  title: string
}

export const HeroPicture: React.FC<HeroPictureProps> = ({
  authorProps,
  category,
  image,
  isColored = false,
  title,
  ...props
}) => (
  <S.NewBlocRow isColored={isColored} isHero {...props}>
    <S.ContentWrapper>
      {category && <S.Categoy>{category}</S.Categoy>}
      <S.Title>{title}</S.Title>
      <S.BlocAuthor title={title} {...authorProps} />
    </S.ContentWrapper>

    <S.ImageWrapper>
      <FormatedImage src={image} format="rectangle" isRounded />
    </S.ImageWrapper>
  </S.NewBlocRow>
)
